import { isBrowser } from 'ws-scripts/modules/environment';

let touchstartX = 0;
let touchendX = 0;

if (isBrowser) {
	document.addEventListener('pointerdown', (e) => {
		touchstartX = e.screenX;
	});

	document.addEventListener('pointerup', (e) => {
		touchendX = e.screenX;
		const wsTagging = window?.DDC.WidgetData['ws-tagging'];

		if (wsTagging) {
			if (touchendX < touchstartX) {
				wsTagging.swipeDirection = 'left';
			} else if (touchendX > touchstartX) {
				wsTagging.swipeDirection = 'right';
			}
		}
	});
}
